import axios from "axios";
import { links } from "./apiLinks";

export const getEvents = async (offset = 0, limit = 20) => {
  try {
    const response = await axios.get(`${links.baseUrl}${links.events.get}`, {
      params: {
        "page[offset]": offset,
        "page[limit]": limit
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

