import { MainPage, EventPage } from '../pages'

export const routes = [
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/:id',
    element: <EventPage />,
  },
]
