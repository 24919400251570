import { useEffect, useState } from 'react';
import { getEvents } from '../../api/getEvents';
import { EventCard, Loader } from '../../components';
import styles from './style.module.scss';
import figitalLight from '../../assets/image/figitalLight.png';
import InfiniteScroll from 'react-infinite-scroller';

interface IItem {
    id: string;
    title: string;
    description: string;
    date_begin: string;
    date_end: string;
    is_active: boolean;
    image: string;
}

export const MainPage = () => {
    const [events, setEvents] = useState<IItem[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 3000);
    }, []);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 3000);
    }, []);
    useEffect(() => {
        try {
            (async () => {
                const events = await getEvents();
                const result = events.data;
                setTotal(events.data.total);
                setEvents(result);
            })();
        } catch (err) {
            console.error(err);
        }
    }, []);

    const getNewEvents = async () => {
        const response = await getEvents(offset + 20, limit + 20);
        setLimit(limit + 20);
        setOffset(offset + 20);
        console.log(response)
        setEvents([...events, ...response.data])
        setTotal(response.total);
        setHasMore(response.total > limit)
    }

    return (
        <>
            {events?.length ? (
                <div className={styles.mainBlock}>
                    <div className={styles.innerBox}>
                        <h1>Анонсы мероприятий</h1>
                        <div className={styles.eventsBox}>

                            <InfiniteScroll
                                className={styles.eventsBox}
                                pageStart={0}
                                loadMore={getNewEvents}
                                hasMore={hasMore}
                                loader={<Loader />}
                            >

                                {events.map((item: IItem) => (
                                    <div className={styles.eventCardBox} key={item.id}>
                                        <EventCard item={item} />
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.emptyBox}>
                    <div>
                        <p className={styles.emptyAnnounce}>Анонсы мероприятий</p>
                        <p className={styles.announceInfo}>Информация об анонсах отсутствует</p>
                    </div>

                    <div className={styles.imageContainer}><img src={figitalLight} alt='' /></div>
                </div>
            )}
        </>
    );
};
